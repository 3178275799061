import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest } from '../../../../../utils/request';
import { isBrowser, capFirstLetter, formatPhoneFromServer, formatDateTime } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';


function formatRow(item) {
  const {id, name, email, zip, phone, payment_type: paymentType, payment_id: paymentId, created_at: created, status, total, products } = item;
  return {
    contact: <span><span className="font-bold">{name}</span><br />{email}<br />{formatPhoneFromServer(phone)}<br />{zip}</span>,
    payment: <span>{paymentType}<br />{paymentId}</span>,
    products: <span>{products.map((product) => (<span key={product.id}>{product.name}</span>))}</span>,
    total: <NumberFormat value={total} displayType="text" thousandSeparator prefix="$" />,
    created: formatDateTime(created),
    status: capFirstLetter(status),
    id,
  };
}

const AdminOrders = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/orders',
        label: 'Orders',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadOrders = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/orders${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setOrders(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadOrders();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'contact',
      label: 'Contact Info',
      // isBold: true,
    },
    {
      key: 'payment',
      label: 'Payment',
    },
    {
      key: 'products',
      label: 'Products',
    },
    {
      key: 'total',
      label: 'Total',
      isBold: true,
    },
    {
      key: 'created',
      label: 'Date',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/app/admin/order/view?id=${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          View
        </Link>
      ),
    },
  ];


  const baseUrl = '/app/admin/orders';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/orders',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'pending',
            label: 'Pending',
          },
          {
            value: 'complete',
            label: 'Complete',
          },
        ],
      },
    ],
    submitLabel: 'Search Orders',
  };

  return (
    <AdminContainer>
      <SEO title="Orders" />
      <Heading title="Orders" />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Orders" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminOrders;
